// build-time variables

export const PRODUCTION = process.env.NODE_ENV === 'production'
export const PUBLIC_PATH = process.env.PUBLIC_PATH

// run-time variables with build-time fallback
export const API_ENDPOINT = getMetaEnv('VUE_APP_API_ENDPOINT', envVariable('VUE_APP_API_ENDPOINT', '/'))
export const AUTH_API_ENDPOINT = getMetaEnv(
  'VUE_APP_AUTH_API_ENDPOINT',
  envVariable('VUE_APP_AUTH_API_ENDPOINT', '/auth'),
)
export const STRIPE_PUBLIC_KEY = getMetaEnv('VUE_APP_STRIPE_PUBLIC_KEY', envVariable('VUE_APP_STRIPE_PUBLIC_KEY'))

export const ORIGIN_ENDPOINT = new URL(API_ENDPOINT).origin

export const SUPABASE_URL = getMetaEnv('VUE_APP_SUPABASE_URL', envVariable('VUE_APP_SUPABASE_URL'))
export const SUPABASE_PUBLIC_KEY = getMetaEnv('VUE_APP_SUPABASE_PUBLIC_KEY', envVariable('VUE_APP_SUPABASE_PUBLIC_KEY'))

export const CAPTCHA_SITE_KEY = getMetaEnv('VUE_APP_CAPTCHA_SITE_KEY', optionalEnvVariable('VUE_APP_CAPTCHA_SITE_KEY'))

function getMetaEnv(name: string, def = ''): string {
  return document.querySelector(`meta[data-env][name=${name}]`)?.getAttribute('content') ?? def
}

export function envVariable(name: string, def?: string): string {
  const envVariable = process.env[name] ?? def

  if (envVariable === undefined) {
    throw new Error(`env variable ${name} not provided`)
  }

  return envVariable
}

export function optionalEnvVariable(name: string): string | undefined {
  return process.env[name]
}
