import { Configuration } from '@/__generated__/api'
import { Session, SupabaseClient } from '@supabase/supabase-js'
import { cloneDeep } from 'lodash'
import { RootStore, SET_ID_TOKEN, UNSET_ID_TOKEN } from './create-store'

export interface IdToken extends Session {
  email: string
}

export function setupIdTokenSync(
  store: RootStore,
  supabase: SupabaseClient,
  apiClientConfiguration: Configuration,
): void {
  const setIdToken = async (): Promise<void> => {
    const { session } = (await supabase.auth.getSession()).data

    if (session && (session.expires_at ?? 0) * 1000 > new Date().getTime()) {
      store.commit(SET_ID_TOKEN, session !== null ? { email: session.user.email, ...cloneDeep(session) } : undefined)

      const { access_token } = session

      apiClientConfiguration.accessToken = access_token
    }
  }

  const unsetIdToken = async (): Promise<void> => {
    store.commit(UNSET_ID_TOKEN)
    apiClientConfiguration.accessToken = undefined
  }

  supabase.auth.onAuthStateChange((event) => {
    if (event === 'SIGNED_IN' || event === 'TOKEN_REFRESHED' || event === 'USER_UPDATED') {
      void setIdToken()
    }

    if (event === 'SIGNED_OUT') {
      void unsetIdToken()
    }
  })

  void setIdToken()
}
