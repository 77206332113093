export type ToastMessage = {
  text: string
  type?: ToastMessageType
}

export enum ToastMessageType {
  ERROR = 'error',
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
}
