import { createClient } from '@supabase/supabase-js'
import { cloneDeep } from 'lodash'
import { SUPABASE_PUBLIC_KEY, SUPABASE_URL } from './env'
import { STORE, SUPABASE } from './features/core/container'
import { SET_ID_TOKEN } from './features/core/store'
import { SafeGet } from './util/container/container'

export const supabase = createClient(SUPABASE_URL, SUPABASE_PUBLIC_KEY)

export async function login(get: SafeGet): Promise<void> {
  const supabase = get(SUPABASE)
  const store = get(STORE)

  const { data } = await supabase.auth.getSession()

  if (data.session === null) {
    await supabase.auth.signOut()

    return
  }

  store.commit(
    SET_ID_TOKEN,
    data.session !== null ? { email: data.session.user.email, ...cloneDeep(data.session) } : undefined,
  )
}
