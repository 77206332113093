import container from '@/bootstrap'
import { ROOT_COMPONENT } from './features/core/container'
import { login } from './supabase'

void (async () => {
  const get = await container()

  await login(get)

  const root = get(ROOT_COMPONENT)
  new root({ propsData: { container: get } }).$mount('#app')
})()
