import { PRODUCTION } from '@/env'
import { ToastMessage, ToastMessageType } from '@/features/core/components/toaster/model'
import delay from '@/util/delay'
import { get } from 'lodash'
import { Route } from 'vue-router'
import { Store } from 'vuex'
import { App } from '../model'
import { IdToken } from './id-token'
import { AddToastErrorMessageParams, AddToastMessageParams, TOAST_DEFAULT_DURATION } from './toast'

export type RootStore = Store<State>
export const SET_ID_TOKEN = 'SET_ID_TOKEN'
export const UNSET_ID_TOKEN = 'UNSET_ID_TOKEN'
export const SET_REALM_ROLES = 'SET_REALM_ROLES'
export const SET_APP_CUSTOMER_CUSTOMER_ID = 'SET_APP_CUSTOMER_CUSTOMER_ID'
export const APPEND_TOAST_MESSAGES = 'APPEND_TOAST_MESSAGES'
export const REMOVE_TOAST_MESSAGES = 'REMOVE_TOAST_MESSAGES'
export const ADD_TOAST_MESSAGES = 'ADD_TOAST_MESSAGES'
export const ADD_TOAST_ERROR_MESSAGES = 'ADD_TOAST_ERROR_MESSAGES'

export class State {
  public production = PRODUCTION
  public title = 'upblock client'
  public declare route?: Route
  public idToken?: IdToken
  public toastMessages: ToastMessage[] = []
}

export async function createStore(apps: Record<string, App>): Promise<RootStore> {
  return new Store({
    state: () => new State(),
    getters: {
      appMap(): Record<string, App> {
        return apps
      },
      app(state): App | undefined {
        return apps[get(state, 'route.meta.app', '') as string]
      },
      userId(state): string | undefined {
        return state.idToken?.user.id
      },
      idToken(state): IdToken | undefined {
        return state.idToken
      },
    },
    mutations: {
      [SET_ID_TOKEN](state, idToken?: IdToken) {
        state.idToken = idToken
      },
      [UNSET_ID_TOKEN](state) {
        state.idToken = undefined
      },
      [APPEND_TOAST_MESSAGES](state, messages: ToastMessage[]) {
        state.toastMessages = state.toastMessages.concat(messages)
      },
      [REMOVE_TOAST_MESSAGES](state, messages: ToastMessage[]) {
        state.toastMessages = state.toastMessages.filter((message) => !messages.includes(message))
      },
    },
    actions: {
      async [ADD_TOAST_MESSAGES](context, params: AddToastMessageParams) {
        context.commit(APPEND_TOAST_MESSAGES, params.messages)
        await delay(params.duration ?? TOAST_DEFAULT_DURATION)
        context.commit(REMOVE_TOAST_MESSAGES, params.messages)
      },
      async [ADD_TOAST_ERROR_MESSAGES](context, params: AddToastErrorMessageParams) {
        const messages = params.map((text) => ({ text, type: ToastMessageType.ERROR }))
        context.commit(APPEND_TOAST_MESSAGES, messages)
        await delay(TOAST_DEFAULT_DURATION)
        context.commit(REMOVE_TOAST_MESSAGES, messages)
      },
    },
  })
}
