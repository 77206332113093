import { Configuration } from '@/__generated__/api'
import { Configuration as AuthConfiguration } from '@/__generated__/auth-api/'
import { AccountTypeEnum } from '@/features/account-types/model'
import { CurrencyEnum } from '@/features/currencies/model'
import { KYCFlowEnum } from '@/features/kyc-flows/model'
import { PayInFlowEnum } from '@/features/pay-in-flows/model'
import { PayOutFlowEnum } from '@/features/pay-out-flows/model'
import { name, optionName } from '@/util/container'
import { Stripe } from '@stripe/stripe-js'
import { SupabaseClient } from '@supabase/supabase-js'
import { AxiosInstance } from 'axios'
import { VueConstructor } from 'vue'
import Router, { RouteConfig } from 'vue-router'
import {
  AccountType,
  ApiClient,
  App,
  AuthApiClient,
  BlockchainNetwork,
  Currency,
  KYCFlow,
  PayInFlow,
  PayOutFlow,
  Stablecoin,
} from '../model'
import { RootStore } from '../store'
import { BlockchainNetworkEnum } from '@/features/blockchain-networks/model'

export const CONTAINER = Symbol('CONTAINER')

export const ROUTES = optionName<RouteConfig[]>('ROUTES')
export const APPS = optionName<App>('APPS')
export const ACCOUNT_TYPES = optionName<AccountType>('ACCOUNT_TYPES')
export const CURRENCIES = optionName<Currency>('CURRENCIES')
export const STABLECOINS = optionName<Currency>('STABLECOINS')
export const PAY_IN_FLOWS = optionName<PayInFlow>('PAY_IN_FLOWS')
export const PAY_OUT_FLOWS = optionName<PayOutFlow>('PAY_OUT_FLOWS')
export const BLOCKCHAIN_NETWORKS = optionName<BlockchainNetwork>('BLOCKCHAIN_NETWORKS')
export const KYC_FLOWS = optionName<KYCFlow>('KYC_FLOWS')
export const API_CLIENTS = optionName<ApiClient>('API_CLIENTS')
export const AUTH_API_CLIENTS = optionName<AuthApiClient>('AUTH_API_CLIENTS')

export const VUE = name<VueConstructor>('VUE')
export const ROOT_COMPONENT = name<VueConstructor>('ROOT_COMPONENT')
export const ROUTER = name<Router>('ROUTER')
export const STORE = name<RootStore>('STORE')
export const API_CLIENT_CONFIGURATION = name<Configuration>('API_CLIENT_CONFIGURATION')
export const AUTH_API_CLIENT_CONFIGURATION = name<AuthConfiguration>('AUTH_API_CLIENT_CONFIGURATION')
export const API_AXIOS = name<AxiosInstance>('API_AXIOS')
export const AUTH_API_AXIOS = name<AxiosInstance>('AUTH_API_AXIOS')
export const SUPABASE = name<SupabaseClient>('SUPABASE')
export const APP_MAP = name<Record<string, App>>('APP_MAP')
export const ACCOUNT_TYPE_MAP = name<Record<AccountTypeEnum, AccountType>>('ACCOUNT_TYPE_MAP')
export const CURRENCY_MAP = name<Record<CurrencyEnum, Currency>>('CURRENCY_MAP')
export const STABLECOIN_MAP = name<Record<CurrencyEnum, Stablecoin>>('STABLECOIN_MAP')
export const PAY_IN_FLOW_MAP = name<Record<PayInFlowEnum, PayInFlow>>('PAY_IN_FLOW_MAP')
export const PAY_OUT_FLOW_MAP = name<Record<PayOutFlowEnum, PayOutFlow>>('PAY_OUT_FLOW_MAP')
export const BLOCKCHAIN_NETWORK_MAP = name<Record<BlockchainNetworkEnum, BlockchainNetwork>>('BLOCKCHAIN_NETWORK_MAP')
export const KYC_FLOW_MAP = name<Record<KYCFlowEnum, KYCFlow>>('KYC_FLOW_MAP')
export const API_CLIENT_MAP = name<Record<string, ApiClient>>('API_CLIENT_MAP')
export const AUTH_API_CLIENT_MAP = name<Record<string, AuthApiClient>>('API_CLIENT_MAP')
export const STRIPE = name<Stripe>('STRIPE')
